import React from 'react';
import PestType from "./PestType";

export default class PestTypeContainer extends React.Component {

	constructor() {
		super()
		this.state = { pestTypes: [] }
		this.toggleActive = this.toggleActive.bind(this)
		this.removeActive = this.removeActive.bind(this)
	}


	componentDidMount() {
		let that = this;

		wp.api.loadPromise.done( function() {
			let pestTypes = new wp.api.models.Pest_type();

			pestTypes
				.fetch()
				.done(function(response) {
					return that.setState({
						pestTypes: response
					});
			});
		});

	}


	removeActive() {
		this.props.setActivePestType(0)
	}


	toggleActive(pestType, i, props, e) {
		let activePestType = this.state.pestTypes[pestType]
		e.preventDefault()

		if ( activePestType.id == this.props.activePestType ) {
			this.removeActive()
			return
		}

		this.props.setActivePestType(activePestType.id)
	}


	render() {
		return (
			<PestType
				pestTypes={this.state.pestTypes}
				toggleActive={this.toggleActive}
				removeActive={this.removeActive}
				{... this.props}
			/>
		)
	}

}
