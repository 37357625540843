import React from 'react'
import Pest from "./Pest"

export default class PestContainer extends React.Component {

	constructor() {
		super()
		this.state = { pests: [] }
		this.toggleActive = this.toggleActive.bind(this)
    this.removeActive = this.removeActive.bind(this)
	}


	componentDidMount() {
		let that = this;

		wp.api.loadPromise.done( function() {
			let pests = new wp.api.collections.Pest()

			pests
				.fetch( { data: { per_page: 99 } } )
				.done(function(response) {
					return that.setState({
						pests : response
					})
			})
		});

	}


	removeActive() {
		this.props.setActivePest(0)
	}


	toggleActive(pest, i, props, e) {
		let activePest = this.state.pests[pest]

		e.preventDefault()

		if ( activePest.id == this.props.activePest ) {
			this.removeActive()
			return
		}

		this.props.setActivePest(activePest.id)
	}


	render() {
		return (
			<Pest
				pests={this.state.pests}
				pestTypes={this.state.pestTypes}
				toggleActive={this.toggleActive}
				removeActive={this.removeActive}
				{... this.props}
			/>
		)
	}

}
