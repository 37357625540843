/**
 * External dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';

import PestType from './PestType';
import PestTypeContainer from './PestTypeContainer';

import Pest from './Pest';
import PestContainer from './PestContainer';



// App
class PestIDs extends React.Component {

	constructor() {
		super()

		let activePestType = this.getParameterByName('pt')
		let activePest = this.getParameterByName('pid')

		this.state = { activePestType, activePest }

		this.setActivePest = this.setActivePest.bind(this)
		this.setActivePestType = this.setActivePestType.bind(this)
	}


	scrollUp() {
		let dist  = Math.abs( jQuery(window).scrollTop() - jQuery("#pests").offset().top )
		let speed = ( dist / 150 ) * 100
		jQuery("html, body").animate({
			scrollTop: jQuery("#pests").offset().top - jQuery("#site-header").outerHeight() - 25
		}, speed);
	}


	getParameterByName(name) {
		let match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}


	setActivePestType(activePestType) {
		if ( activePestType > 0 )
			this.scrollUp()

		let loc = window.location
		let path = loc.origin + loc.pathname + '?pt=' + activePestType
		history.pushState({path: path}, "", path);

		this.setState(function(previousState, currentProps) {
			return {
				activePestType: activePestType,
				activePest: 0
			};
		});
	}


	setActivePest(activePest) {

		let loc = window.location
		let path = loc.origin + loc.pathname + '?pt=' + this.state.activePestType + '&pid=' + activePest
		history.pushState({path: path}, "", path);

		this.setState(function(previousState, currentProps) {
			return {activePest: activePest};
		})
	}


	render() {
		return (
			<div>

				<PestContainer
					{... this.state}
					setActivePest={this.setActivePest}
				/>

				<PestTypeContainer
					{... this.state}
					setActivePestType={this.setActivePestType}
				/>
			</div>
		)
	}

}


/**
 * Make it so…
 */
ReactDOM.render(
	<PestIDs />, document.getElementById( 'pests' )
);