import React from 'react';

if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this === null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

// Dumb component
export default class Pest extends React.Component {

  constructor(props) {
    super(props)
    this.createPestMarkup = this.createPestMarkup.bind(this)
    //this.activePest = this.activePest.bind(this)
  }


	createPestMarkup(pest) {
		return {__html: pest }
	}


	activePest() {
		let that = this
		let currentPest = this.props.pests.find( function(pest, index, array) {
			return pest.id == that.props.activePest
		})

		if ( ! currentPest )
			return

		return (
			<div className="o-media current-pest">
				<img src={currentPest.featured_img} alt={currentPest.title.rendered} className="o-media__figure" />
				<div className="o-media__body">
					<h3 className="current-pest__title">
						{currentPest.title.rendered}
						<button className="close" onClick={that.props.removeActive}>
						<svg className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
					</button>
					</h3>
					<h2 className="current-pest__latin-name">
						{currentPest.latin_name}
					</h2>
					<div dangerouslySetInnerHTML={that.createPestMarkup(currentPest.content.rendered)} />
				</div>
			</div>
		)
	}


  render() {

		let that = this

		let pests = this.props.pests.map(function(pest,i) {
			return (
				<div
					pest={pest.slug}
					key={pest.id}
					pestType={pest.pest_type[0]}
					className={(that.props.activePestType == pest.pest_type ) ? 'active pest' : 'pest'}
					onClick={that.props.toggleActive.bind(pest, i, that.props, event)}
				>
					<a href="#0">
						<img src={pest.featured_img} alt={pest.title.rendered} />
						<h4>{pest.title.rendered}</h4>
						<p>{pest.latin_name}</p>
						</a>
			  </div>
			);
		});

		return (
			<div>
				<section className={(this.props.activePest > 0) ? 'active-pest open' : 'active-pest-list'}>
					{this.activePest()}
				</section>
				<section className={(this.props.activePestType > 0) ? 'pest-list open' : 'pest-list'}>
					{pests}
				</section>
			</div>
		);

  }
}
// <div dangerouslySetInnerHTML={that.createPestMarkup(pest.content.rendered)}></div>

//export default Pest;