import React from 'react'

// Dumb component
export default class Pest extends React.Component {

  constructor(props) {
    super(props)
  }


	pestTypeTitle() {
		let that = this
		let title = this.props.pestTypes.find( function(pestType, index, array) {
			return pestType.id == that.props.activePestType
		})

		if ( title ) {
			return <span dangerouslySetInnerHTML={{__html: title.name}}></span>
			//return title.name
		}

		return
	}


  render() {

  	var that = this

  	var pestTypes = this.props.pestTypes.map(function(pestType,i) {

			return (
				<div className="pest-type" key={pestType.id}>
					<a
						href="#0"
						onClick={that.props.toggleActive.bind(pestType, i, that.props, event)}
						className={(that.props.activePestType == pestType.id ) ? 'active' : ''}
					>
						<svg className={"icon icon-" + pestType.slug}>
							<use xlinkHref={"#icon-" + pestType.slug}></use>
						</svg>
						<b dangerouslySetInnerHTML={{__html: pestType.name}}></b>
					</a>
				</div>
			)
		})

		return (
			<section className="pest-types">
				<div className={(that.props.activePestType) ? 'active pest-types-title' : 'pest-types-title'}>
					{this.pestTypeTitle()}
					<button className="close" onClick={that.props.removeActive}>
						<svg className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
					</button>
				</div>
			  {pestTypes}
			</section>
		)

  }
}
//

//export default Pest